import { Link } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import "../scss/termsofuse.scss";
const TermsOfUse = (props) => {
    return (
        <Layout title="Terms of Use" description="Your access to and use of FIRST PRIDE websites on which these terms reside, and the features at this site (collectively, the “Site”) are subject to these Terms of Use. ">
            <div className="termsofuse">
                <div className="termsBg">
                    <h1>Terms of Use</h1>
                </div>
                <div className="termsContent">
                    <dl className="termsList" >
                        <dt>1. Introduction</dt>
                        <dd>
                            Please review these Terms of Use (and together with all other terms or policies posted on the Tyson Site, including any updates, collectively, the “
                            <b>Terms</b>”) carefully. These Terms govern your access to and use of the Tyson Site. By using the Tyson Site, you affirm you are of legal age to enter
                            into these Terms. If you violate or do not agree to these Terms, then your access to and use of the Tyson Site is unauthorized. <br />
                            <br />
                            THESE TERMS INCLUDE A BINDING ARBITRATION CLAUSE (SECTION 15) REQUIRING YOU AND US TO ARBITRATE OUR CLAIMS INSTEAD OF SUITING IN COURT. THESE TERMS ALSO
                            LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE (SECTION 13).
                            <br />
                            <br />
                            When we say:
                            <ul>
                                <li>
                                    “<b>You</b>” or “<b>your</b>” means any user, or any entity on whose behalf a user is acting, of any Tyson Site. If you use any Tyson Site on
                                    behalf of a company, organization or other entity, then you warrant you are an authorized representative of that entity and you agree to these
                                    Terms on that entity’s behalf.
                                </li>
                                <li>
                                    “<b>Tyson</b>,” “<b>we</b>,” “<b>us</b>” or “<b>our</b>” means Tyson Foods, Inc., the owner and operator of the Tyson Site, and all subsidiaries
                                    or affiliates of Tyson Foods, Inc. and each entity’s officers, directors, employees, consultants, agents, representatives, contractors and
                                    licensors (collectively, “<b>Tyson</b>”).
                                </li>
                                <li>
                                    "<b>Content</b>” means descriptions, reviews, comments, messages, reviews, communications, feedback, concepts, know how, submissions,
                                    suggestions, questions, and other information, data, content, and materials (including page headers, images, text, illustrations, formats,
                                    graphics, logos, hashtags, designs, icons, photographs, software programs, audio, music or video clips or downloads, and written and other
                                    materials).
                                </li>
                                <li>
                                    “<b>Intellectual Property Rights</b>” means all patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark,
                                    trade dress and service mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come
                                    into existence, and all applications therefore and registrations, renewals and extensions thereof, under the laws of any state, country,
                                    territory or other jurisdiction.
                                </li>
                                <li>
                                    “<b>Materials</b>” means materials that Tyson makes available on or through use of the Tyson Site.
                                </li>
                                <li>
                                    “<b>make available</b>” means post, transit, publish, upload, distribute, transmit, display, provide or otherwise submit or make available
                                    (including through any part of the Tyson Site administered by third-party social media platform that allow interaction with the Tyson Site
                                    through the tools offered by such social media platforms).
                                </li>
                                <li>
                                    “<b>Products</b>” means Content made available by Tyson, the Tyson Site, the Materials and Services.
                                </li>
                                <li>
                                    “<b>Services</b>” means services Tyson makes available through the use of the Tyson Site.
                                </li>
                                <li>
                                    “<b>Tyson Site(s)</b>” means{" "}
                                    <a href="http://www.firstpride.com" target="_blank" rel="noopener noreferrer">
                                        www.firstpride.com
                                    </a>
                                    ,{" "}
                                    <a href="http://www.firstpride.com.my" target="_blank" rel="noopener noreferrer">
                                        www.firstpride.com.my
                                    </a>
                                    ,{" "}
                                    <a href="http://www.firstpride.sg" target="_blank" rel="noopener noreferrer">
                                        www.firstpride.sg
                                    </a>
                                    ,{" "}
                                    <a href="http://www.firstpridethailand.com" target="_blank" rel="noopener noreferrer">
                                        www.firstpridethailand.com
                                    </a>
                                    ,{" "}
                                    <a href="http://www.tyson.my" target="_blank" rel="noopener noreferrer">
                                        www.tyson.my
                                    </a>
                                    ,{" "}
                                    <a href="http://www.tyson.sg" target="_blank" rel="noopener noreferrer">
                                        www.tyson.sg
                                    </a>{" "}
                                    and all related functionality and Content offered by or for Tyson on or through those website and any applications, systems, servers and
                                    networks used to make the Tyson Sites available.
                                </li>
                            </ul>
                        </dd>
                        <dt>2. Updates</dt>
                        <dd>
                            We may update these Terms at any time by posting an updated Terms of Use (with a revision date included) on the Tyson Site or otherwise providing you
                            notice of the changes. By continuing your access to or use of the Tyson Site after we provide notice of any changes, you accept the updated Terms. Any
                            changes made to the Terms will not apply to any dispute between you and us arising before the date on which we posted the updated Terms or otherwise
                            notified you of such changes.
                        </dd>
                        <dt>3. YOUR USE OF THE TYSON SITE</dt>
                        <dd>
                            In connection with the Tyson Site, you may not nor permit, encourage, assist or allow any third party to:
                            <ul>
                                <li>make available any Content prohibited by Section 8 below;</li>
                                <li>use the Products for any commercial, fraudulent or otherwise tortious or unlawful purpose;</li>
                                <li>remove any copyright, trademark, or other proprietary rights notice from the Products;</li>
                                <li>
                                    systematically download and store, reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan, distribute, or
                                    otherwise exploit the Products;
                                </li>
                                <li>
                                    use the Products or Tyson&rsquo;s trademark, URL, name, logo or brand within any meta tags, metadata or hidden texts or to send any form or
                                    method of advertising, promotional or other unsolicited materials, content or products;
                                </li>
                                <li>
                                    pose a security risk to, restrict or inhibit any person&rsquo;s use of or interfere with or disrupt the operation of the Tyson Site, including
                                    without limitation by hacking or defacing any portion of the Tyson Site or violating any requirement, procedure or policy of Tyson;
                                </li>
                                <li>
                                    reverse engineer, decompile or disassemble any portion of the Tyson Site or frame, mirror or otherwise incorporate any portion of the Products
                                    into any product or service;
                                </li>
                                <li>
                                    make available through or in connection with the Tyson Site any virus, worm, Trojan horse, Easter egg, time bomb, spyware or other computer
                                    code, file or program that is or is potentially harmful or invasive or intended to damage or hijack the operation of, or to monitor the use of,
                                    any hardware, software or equipment (collectively, &ldquo;<b>Computer Virus(es)</b>&rdquo;);
                                </li>
                                <li>harvest or collect information about users of the Tyson Site;</li>
                                <li>
                                    use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, &ldquo;scrape,&rdquo;
                                    &ldquo;data mine&rdquo; or otherwise gather any Content, or reproduce or circumvent the navigational structure or presentation of the Tyson
                                    Site;
                                </li>
                                <li>cause injury or property damage to Tyson or any person or entity; or</li>
                                <li>violate any law, rule, or regulation, or these Terms.</li>
                            </ul>
                        </dd>
                        <dt>4. MODIFICATION OF PRODUCTS, SUSPENSION AND TERMINATION</dt>
                        <dd>
                            Tyson may modify, restrict, suspend or discontinue all or any portion of the Products at any time without notice to you. These terms are effective
                            unless and until they are terminated by us or by you. You may terminate these Terms at any time, provided you discontinue any further use of the
                            Products. We may suspend or terminate these Terms at any time, immediately without notice, and deny you access to the Tyson Site, if in our sole
                            discretion, you fail to comply with any provision of these Terms.
                        </dd>
                        <dt>5. TYSON INTELLECTUAL PROPERTY</dt>
                        <dd>
                            Except for any Content provided by you and licensed by Tyson as described in Section 8 below, all Products and any Intellectual Property Rights related
                            thereto, shall be and remain the sole property of Tyson. You shall have no right or interest in, or claim to any Products or any derivatives thereof or
                            modifications or adaptations thereto, except as provided in these Terms. For clarification, Materials are licensed (not sold) to end users. Resale or
                            unauthorized distribution of Materials is strictly prohibited. For the purposes of these Terms, “resale” means purchasing or intending to purchase any
                            Materials from Tyson for the purpose of engaging in a commercial sale of the same Materials to a third party.
                        </dd>
                        <dt>6. DISCLAIMER OF WARRANTIES</dt>
                        <dd>
                            TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, ALL PRODUCTS MADE AVAILABLE TO YOU ARE PROVIDED “AS IS” OR “AS AVAILABLE.” TYSON DISCLAIMS ALL
                            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT; OR WITH RESPECT TO THE
                            MATERIALS AND SERVICES, ANY WARRANTIES OF ACCURACY, RELIABILITY, TIMELINESS, USEFULNESS, CURRENTNESS, COMPLETENESS OR PERFORMANCE; OR WITH RESPECT TO
                            THE TYSON SITE, ANY WARRANTIES OF OPERATION, SECURITY, AVAILABILITY OR FUNCTIONALITY.
                            <br />
                            <br />
                            THIS DISCLAIMER CONSTITUTES AN ESSENTIAL PART OF THESE TERMS OF USE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU ASSUME FULL RESPONSIBILITY
                            AND SOLE RISK FOR YOUR USE OF THE PRODUCTS. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE FOREGOING LIMITATIONS MAY
                            NOT APPLY TO YOU.
                        </dd>
                        <dt>7. SUBMISSION AND LICENSE OF YOUR CONTENT</dt>
                        <dd>
                            Tyson provides functionality to enable users to make available Content in connection with the Tyson Site. You agree you are responsible for all Content,
                            and the legality, accuracy, completeness appropriateness, originality and rights related thereto, you make available with the Tyson Site. <br />
                            <br />
                            You agree that you will not make available any Content in connection with the Tyson Site that:
                            <ul>
                                <li>is false, fraudulent, inaccurate, impersonating, misrepresenting or misleading;</li>
                                <li>
                                    contains your or any other person&rsquo;s confidential personally identifiable information, except for the authorized use of Products as set
                                    forth in these Terms;
                                </li>
                                <li>violates these Terms or other Tyson policy, any local, state, federal or international laws or is otherwise tortious;</li>
                                <li>would infringe on the rights of others (including Tyson);</li>
                                <li>
                                    is, or otherwise promotes Content or action that is, obscene, indecent, pornographic, objectionable, derogatory, defamatory, threatening,
                                    harassing, abusive, slanderous, violent, hateful or embarrassing to any other person, entity or group as determined by Tyson in its sole
                                    discretion;
                                </li>
                                <li>
                                    victimizes, harasses, degrades or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race,
                                    ethnicity, age or disability;
                                </li>
                                <li>contains or relates to advertisements, solicitations, or spam links to other web sites or individuals, chain letters or pyramid schemes; or</li>
                                <li>is intended to cause harm, damage or interference.</li>
                            </ul>
                            By making available Content, you warrant that:
                            <ul>
                                <li>you have the authority to grant the rights in such Content as explained in these Terms;&nbsp;</li>
                                <li>your Content you provide is truthful, accurate and has been legally obtained;&nbsp;</li>
                                <li>your Content are gratuitous, unsolicited and without restriction; and&nbsp;</li>
                                <li>your Content does and will comply with these Terms. &nbsp;</li>
                            </ul>
                            You retain ownership of the Content you make available and understand that making available Content is not a substitute for registering such Content
                            with the U.S. Copyright Office or other rights organization. You grant Tyson a royalty-free, perpetual, irrevocable, worldwide, nonexclusive, fully
                            sublicensable license to use, reproduce, create derivative works from, modify, publish, edit, translate, distribute, perform and display (publicly or
                            otherwise) any Content, any name, voice and/or likeness associated with any Content, that you make available, in any media or medium, and in any form,
                            format or forum, now known or hereafter developed.
                            <br />
                            <br />
                            You further agree that Tyson is free to use any Content made available by you for any purpose, but not under obligation to exercise such right or to
                            otherwise provide any attribution for any Content. For any Content made available by you, Tyson is, and will be, under no obligation to: (1) maintain
                            any Content in confidence; (2) pay any compensation for any Content; or (3) respond to any Content.
                        </dd>
                        <dt>8. MONITORING OF CONTENT</dt>
                        <dd>
                            Tyson will have the right, but not the obligation, in our sole discretion to monitor, evaluate and analyze Content, and any access to or use of the
                            Tyson Site, including to determine compliance with these Terms and any other operating rules that may be established by Tyson from time to time. Tyson
                            will also have the right, but not the obligation, in our sole discretion to edit, modify, move, delete or refuse to make available any Content made
                            available through the Tyson Site for any reason, including for violation of these Terms, legal or other reasons. Despite this right, you are solely
                            responsible for any Content you make available. You also understand that you are solely responsible for your interactions with other users of the Tyson
                            Site and may be exposed to Content from other users that is inaccurate, objectionable or otherwise unsuitable. You agree that Tyson shall not be liable
                            for any damages you may incur as a result of such interactions with other users or such Content.
                        </dd>
                        <dt>9. Third Party Sites</dt>
                        <dd>
                            References on the Tyson Site to any names, marks, products or services by third parties, or links to third-party sites or information, are not owned,
                            controlled, endorsed, sponsored or recommended by Tyson. Tyson is not responsible for the content of any third-party linked site or any link contained
                            in a linked site, including any third-party social media or mobile application platform with which the Tyson Site may operate or otherwise interact.
                            Tyson is not responsible for the acts or omissions of any operator of any such site or platform nor is it a party to or in any way monitoring any
                            transactions you have with a third party. Your use of any such third party site or platform is at your own risk and will be governed by the third
                            party’s terms and policies (including its privacy policy).
                            <br />
                            <br />
                            Portions of the Products are performed by third parties. You may not use the applicable Products except in compliance with such third party’s Terms of
                            Service. Unless required by applicable law, Products delivered by third parties are provided on an “AS IS” basis without warranties or conditions of any
                            kind, either express or implied. Please see applicable Terms of Service for specific language governing permissions and limitations.
                        </dd>
                        <dt>10. Privacy</dt>
                        <dd>
                            You agree that any personal information that you provide through the Tyson Site will be used by Tyson in accordance with Tyson’s{" "}
                            <Link to="/privacypolicy">Privacy Policy</Link>, which may be updated from time to time.
                        </dd>
                        <dt>11. MAKING A CLAIM OF COPYRIGHT INFRINGEMENT</dt>
                        <dd>
                            We respect the intellectual property of others. If you believe your work has been copied and is accessible on the Tyson Site in a way that infringes
                            your copyright, you may request removal of those materials from the Tyson Site by submitting written notification. Your notice must include
                            substantially the following:
                            <ul>
                                <li>Adequate information by which we can contact you&nbsp;</li>
                                <li>
                                    Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works, a representative list of
                                    such works;
                                </li>
                                <li>Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material</li>
                                <li>
                                    A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the
                                    law;
                                </li>
                                <li>A statement that the information in the written notice is accurate; and</li>
                                <li>A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner</li>
                                <li>Your physical or electronic signature.</li>
                            </ul>
                            If you believe material you posted on the Tyson Site was removed by mistake or misidentification, you may file a counter notification with us by
                            submitting written notification. We will restore your removed content if the party filing the notice does not file a court action against you within
                            twenty business days of receiving the copy of your counter notice. Your counter notice must include substantially the following:
                            <ul>
                                <li>Adequate information by which we can contact you&nbsp;</li>
                                <li>
                                    Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works, a representative list of
                                    such works;
                                </li>
                                <li>Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material</li>
                                <li>
                                    A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the
                                    law;
                                </li>
                                <li>A statement that the information in the written notice is accurate; and</li>
                                <li>A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner</li>
                                <li>Your physical or electronic signature.</li>
                            </ul>
                            If you knowingly, materially misrepresent that material or activity on the Tyson Site is infringing your copyright or was removed or disabled by mistake
                            or misidentification, you may be held liable for damages, including costs and attorneys' fees.
                            <br />
                            <br />
                            In accordance with other applicable law, it is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat
                            infringers. We may also at our sole discretion limit access to the services and/or terminate the accounts of any users who infringe any intellectual
                            property rights of others, whether or not there is any repeat infringement.
                            <br />
                            <br />
                            <pre>
                                TYSON INTERNATIONAL APAC LTD.
                                <br />
                                89 AIA Capital Centre Building, 22nd Floor,
                                <br />
                                Ratchadaphisek Road, Din Daeng District,
                                <br />
                                Din Daeng Sub-district, Bangkok 10400 Thailand
                                <br />
                                Tel: +662 0948888
                            </pre>
                            If the information you submit is not accurate or complete, we may be unable to process or respond to your request. If you have questions about what
                            intellectual property infringement is, or whether your rights were infringed, consider seeking legal advice.
                        </dd>
                        <dt>12. LIMITATION OF LIABILITY</dt>
                        <dd>
                            TO THE FULLEST EXTENT PROVIDED BY APPLICABLE LAW:
                            <ol type="A" className="alphaList">
                                <li>
                                    INDIRECT DAMAGES. TYSON WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL LOSSES OR DAMAGES OF ANY NATURE, EVEN IF YOU
                                    HAVE BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.{" "}
                                </li>
                                <li>TOTAL LIABILITY. TYSON WILL NOT BE LIABLE FOR ANY DAMAGES IN EXCESS OF THE FEES PAID BY YOU IN CONNECTION WITH YOUR USE OF THE PRODUCTS. </li>
                                <li>
                                    CONDUCT AND CONTENT OF THIRD PARTIES. TYSON WILL NOT BE LIABLE FOR: (I) ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF, OR FOR ANY CONTENT
                                    FROM, ANY OTHER USER OF THE TYSON SITE; OR (II) ANY CONTENT OF ANY THIRD PARTY LINKED OR REFERRED TO ON THE TYSON SITE OR YOUR INTERACTION WITH
                                    ANY SUCH THIRD PARTY.{" "}
                                </li>
                                <li>
                                    INFORMATION SECURITY. TYSON WILL NOT BE LIABLE FOR ANY UNAUTHORIZED ACCESS TO, IMPERMISSIBLE DISCLOSURE OR ALTERATION OF, INJURY, LOSS OR DAMAGE
                                    TO YOUR INFORMATION THAT RESULTS FROM YOUR USE OF THE PRODUCTS, INCLUDING THOSE RELATED TO YOUR USE OF THE TYSON SITE.
                                </li>
                            </ol>
                        </dd>
                        <dt>13. INDEMNIFICATION</dt>
                        <dd>
                            You agree to defend (at Tyson’s option), indemnify and hold Tyson harmless from and against all liabilities, claims, damages, costs, expenses, including
                            attorneys’ fees and costs, arising from or related to:
                            <ul>
                                <li>your access to or use of the Products;</li>
                                <li>any claim or damages arising from any Content made available through your account;</li>
                                <li>any breach of these Terms;</li>
                                <li>any violation of applicable law, rules or regulations; or</li>
                                <li>
                                    any claim of infringement, misappropriation or misuse of Content or other violation of a third party&rsquo;s rights related thereto (including
                                    any privacy rights or Intellectual Property Rights) for any Content you make available.
                                </li>
                            </ul>
                            Tyson reserves the right, at our expense, to assume exclusive defense and control of any matter otherwise subject to indemnification by you and, in any
                            case, you agree to cooperate with Tyson if and as requested by Tyson in the defense and settlement of such matter.
                        </dd>

                        <dt>14. DISPUTES AND ARBITRATION</dt>
                        <dd>
                            THIS SECTION MAY AFFECT YOUR LEGAL RIGHTS. BY ACCESSING OR USING THE TYSON SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO THESE
                            TERMS. <br />
                            <br />
                            Any dispute or claim relating in any way to your use of the Tyson Site or the Products, whether based in contract, tort, statute, fraud,
                            misrepresentation or any other legal theory, will be resolved by final and binding arbitration, rather than in court. The Federal Arbitration Act and
                            federal arbitration law apply to this agreement.
                            <br />
                            <br />
                            There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis
                            the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow these Terms as a court would.
                            <br />
                            <br />
                            To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to TYSON INTERNATIONAL APAC LTD. at 89 AIA
                            Capital Centre Building, 22nd Floor, Ratchadaphisek Road, Din Daeng District, Din Daeng Sub-district, Bangkok 10400 Thailand. The arbitration will be
                            conducted by the American Arbitration Association (AAA) under its rules. The AAA's rules are available at www.adr.org or by calling 1-800-778-7879.
                            Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules. You may choose to have the arbitration conducted by
                            telephone, based on written submissions, or in person in the county where you live or at another mutually agreed location.
                            <br />
                            <br />
                            We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative
                            action. If for any reason a claim proceeds in court rather than in arbitration we each waive any right to a jury trial. Class arbitrations and class
                            actions are not permitted and you are agreeing to give up the ability to participate in a class action. We also both agree that you or we may bring suit
                            in court to enjoin infringement or other misuse of intellectual property rights.
                        </dd>
                        <dt>15. General</dt>
                        <dd>
                            These Terms shall be governed and construed in accordance with the laws of the State of Arkansas. These Terms represent the complete agreement and
                            understanding between you and us and supersede all prior agreements and representations between the parties with respect to the subject matter of these
                            Terms. These Terms do not, and shall not be construed to, create any partnership, joint venture, employer-employee, agency, or franchisor-franchisee
                            relationship between you and us. Headings used in these Terms are for reference purposes only and in no way define or limit the scope of the section. If
                            any provision of these Terms is held to be unenforceable for any reason, such provision will be reformed only to the extent necessary to make it
                            enforceable and the other terms of these Terms will remain in full force and effect. The failure of Tyson to act with respect to a breach of these Terms
                            by you or others does not constitute a waiver and will not limit Tyson’s rights with respect to such breach or any subsequent breaches. You may not
                            assign, transfer, or sublicense any or all of your rights or obligations under these Terms without our express prior written consent. We may assign,
                            transfer, or sublicense any or all of our rights or obligations under these Terms without restriction. Any use of the term “including” or variations
                            thereof in these Terms shall be construed as if followed by the phrase “without limitation.” Without limitation, a printed version of these Terms and of
                            any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms to the same
                            extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
                            <br />
                            <br />
                            The following sections will survive any termination of these Terms: “Your Use of the Tyson Site,” “Modification of Products, Suspension and
                            Termination,” “Tyson Intellectual Property; License Grant and Use of Tyson Materials,” “Disclaimer of Warranties,” “Submission and License of Your
                            Content,” “Monitoring of Content,” “Third Party Sites,” “Privacy,” “Making a Claim of Copyright Infringement,” “Limitation of Liability,”
                            “Indemnification,” “Dispute Arbitration,” and “General.”
                        </dd>
                        <dt>16. Electronic Notices</dt>
                        <dd>
                            Electronic Notices. You consent to electronic communications delivery of notices and other communications (including email or the Tyson Site). All such
                            notices and other communications sent to an email address shall be deemed received upon: (i) the sender’s receipt of an acknowledgement from the
                            intended recipient (such as by the “return receipt requested” function, return email or other acknowledgement) if sent to an email address or (ii) the
                            receipt by the intended recipient at its email address that such notice or communication is available and identifying the website address if posted to
                            the Tyson Site. You are responsible for the accuracy and currentness of any email address you provide to Tyson.
                        </dd>
                        <dt>17. How to Contact us</dt>
                        <dd>
                            If you have any questions about these Terms or otherwise need to contact us for any reason, you can reach us by mail at
                            <pre>
                                TYSON INTERNATIONAL APAC LTD.
                                <br />
                                89 AIA Capital Centre Building, 22nd Floor,
                                <br />
                                Ratchadaphisek Road, Din Daeng District,
                                <br />
                                Din Daeng Sub-district, Bangkok 10400 Thailand
                                <br />
                                Tel: +662 0948888
                            </pre>
                        </dd>
                    </dl>
                </div>
            </div>
        </Layout>
    );
};

export default TermsOfUse;
